import React, { useEffect, useState } from "react";
import { publishDeployment, cancelDeployment } from "../../services/deployment";
import { useQueryClient, useMutation, useIsFetching } from "react-query";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { HOURS24_TO_MILLISECONDS } from "../../services/Constants";
import { useToast } from "../toast";

const PublishDeployment = ({ data, listView = true }) => {
  const { row: { deploymentId, deploymentStatus, campaignEndDate } = {} } =
    data || {};
  // Access the client
  const queryClient = useQueryClient();
  const [disabled, setDisabled] = useState(false);
  const { addToast } = useToast();
  const isFetching = useIsFetching({ queryKey: ["deployments"] });
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const publishCampaignIfValid = (deploymentId) => {
    if (campaignEndDate) {
      var eta_ms = new Date(campaignEndDate).getTime() - Date.now();
      if (eta_ms >= HOURS24_TO_MILLISECONDS) {
        handlePublish(deploymentId);
      } else {
        setDisabled(true);
        addToast({
          type: "error",
          message:
            "Cannot publish deployment when the campaign end date is less than 24 hours from current time!",
          autoClose: 3000,
        });
      }
    } else {
      handlePublish(deploymentId);
    }
  };

  // Mutations
  const { mutate: handlePublish, isLoading } = useMutation(publishDeployment, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries("deployments");
    },
  });

  const { mutate: handleCancel, isLoading: cancelIsLoading } = useMutation(
    cancelDeployment,
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries("deployments");
      },
      onError: (err) => {
        const {
          response: { data: errorData },
        } = err;
        if (err && errorData) {
          if (errorData.statusCode !== 404) {
            addToast({
              type: "error",
              message: errorData.message,
              autoClose: 3000,
            });
            handleOpen();
          }
        }
      },
    }
  );

  useEffect(() => {
    if (campaignEndDate) {
      var eta_ms = new Date(campaignEndDate).getTime() - Date.now();
      if (eta_ms <= HOURS24_TO_MILLISECONDS) {
        setDisabled(true);
      }
    }
  }, [campaignEndDate]);

  return (
    <div>
      {listView && (
        <Button
          size="small"
          component={RouterLink}
          to={`/Deployments/${deploymentId}`}
        >
          Details
        </Button>
      )}
      {deploymentStatus === "NOT_PUBLISHED" && (
        <Check I={CAN.PUBLISH} a={SUBJECTS.DEPLOYMENT}>
          <>
            {listView && <span>|</span>}
            <Button
              size="small"
              onClick={() => publishCampaignIfValid(deploymentId)}
              disabled={isLoading || isFetching || disabled}
            >
              {isLoading || isFetching ? <HourglassTopIcon /> : "Publish"}
            </Button>
          </>
        </Check>
      )}
      {deploymentStatus === "PUBLISHED" && (
        <Check I={CAN.CANCEL} a={SUBJECTS.DEPLOYMENT}>
          <>
            {listView && <span>|</span>}
            <Button
              size="small"
              onClick={() => handleCancel(deploymentId)}
              disabled={cancelIsLoading || isFetching}
            >
              {cancelIsLoading || isFetching ? <HourglassTopIcon /> : "Cancel"}
            </Button>
          </>
        </Check>
      )}
      <CancelAlert {...{ open, handleClose }} />
    </div>
  );
};

PublishDeployment.defaultProps = {
  data: {},
};

PublishDeployment.propTypes = {};

export default PublishDeployment;

const CancelAlert = ({ open, handleClose }) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Cancel Deployment
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          <>Please wait for the deployment to finish.</>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
